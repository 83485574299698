import React from 'react';

const HeaderPage = () => (
    <>
    <header>
        <img src={require("../images/headshot.jpg")} alt="Headshot"/>
        <h1>Alec Flanigan</h1>
    </header>
    </>
);

export default HeaderPage;